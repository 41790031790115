<template>
  <v-card :disabled="loading">
    <ValidationObserver ref="budgetForm">
      <v-form @submit.prevent="handleSubmit">
        <v-card-title>
          {{ title }}
        </v-card-title>

        <v-card-text>
          <v-subheader>
            {{ $capitalize($tc("model.products_items_card")) }}
          </v-subheader>
          <v-row v-for="(budgetItem, index) in budgetItems" :key="index">
            <v-col>
              <component
                v-bind:is="itemComponent"
                v-model="budgetItems[index]"
                :ref="`budgetItem${index}`"
                @delete="handleDeleteItem(index)"
                :coverages="lead.policy.coverages"
                :units="units"
              />
            </v-col>
          </v-row>

          <v-row dense>
            <v-col>
              <v-btn @click="handleAddNewItem" color="primary" text>
                <v-icon left>mdi-plus</v-icon>
                {{ $capitalize($tc("model.products_add_item")) }}
              </v-btn>
            </v-col>
          </v-row>

          <v-row class="mt-10">
            <v-col>
              <div v-if="showDetails">
                <v-subheader class="pl-0 mb-5">
                  {{ $capitalize($tc("model.marketplace_details")) }}
                </v-subheader>
                <ValidationProvider
                  name="product_budget.issued_by"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <ContactInput
                    name="product_budget.issued_by"
                    v-model="product_budget.issued_by"
                    :label="$capitalize($tc('model.products_repairman_title'))"
                    outlined
                    dense
                    :error-messages="errors"
                  />
                </ValidationProvider>

                <ValidationProvider
                  name="product_budget.external_document_id"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <AttachmentInput
                    :label="
                      $capitalize($tc('model.products_attachment_reference'))
                    "
                    v-model="product_budget.external_document_id"
                    :attachments="attachments"
                    :loading="loadingAttachments"
                    outlined
                    dense
                    :error-messages="errors"
                  />
                </ValidationProvider>

                <ValidationProvider
                  name="product_budget.sent_by"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <ParticipantPicker
                    :label="$capitalize($tc('model.products_sent_by'))"
                    v-model="product_budget.sent_by"
                    :lead="lead"
                    return-object
                    outlined
                    dense
                    :error-messages="errors"
                    insured
                  />
                </ValidationProvider>
              </div>
            </v-col>
            <v-col cols="12" md="6">
              <v-subheader>
                {{ $capitalize($tc("model.products_totalizers_subheader")) }}
              </v-subheader>
              <v-list>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title class="subtitle-2">{{
                      $capitalize($tc("model.products_subtotal_title"))
                    }}</v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action>
                    <div class="mr-8">
                      {{ euro(subtotal) }}
                    </div>
                  </v-list-item-action>
                </v-list-item>
                <v-divider></v-divider>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title class="subtitle-2">{{
                      $capitalize($tc("model.products_devaluation"))
                    }}</v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action>
                    <div class="mr-8">
                      -{{
                        budgetTool.formatVatPercentage(depreciation_percentage)
                      }}
                      %
                    </div>
                  </v-list-item-action>
                </v-list-item>
                <v-divider></v-divider>
                <ValidationProvider name="additionalValue" rules="">
                  <AdditionalValueItem v-model="additionalValue" />
                </ValidationProvider>
                <v-divider></v-divider>
                <ValidationProvider name="vat_percentage" rules="">
                  <IvaValueItem v-model="vat_percentage" :subtotal="subtotal" />
                </ValidationProvider>
                <v-divider></v-divider>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title class="subtitle-2">{{
                      $capitalize($tc("model.products_total_title"))
                    }}</v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action>
                    <div class="mr-8 subtitle-2">
                      {{ euro(total) }}
                    </div>
                  </v-list-item-action>
                </v-list-item>
              </v-list>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn type="submit" color="primary" :loading="loading">{{
            $capitalize($tc("model.products_save_button"))
          }}</v-btn>
        </v-card-actions>
      </v-form>
    </ValidationObserver>
  </v-card>
</template>

<script>
import AttachmentInput from "@/components/Lead/AttachmentInput";
import AdditionalValueItem from "@/components/Budget/AditionalValueItem";
import IvaValueItem from "@/components/Budget/IvaValueItem";
import CompensationBudgetItem from "@/components/Budget/Compensation/CompensationBudgetItem";
import ReparationBudgetItem from "@/components/Budget/Reparation/ReparationBudgetItem";
import SubstituitionBudgetItem from "@/components/Budget/Substituition/SubstituitionBudgetItem";
import Formatter from "@/tools/money";
import ContactInput from "@/components/ContactInput";
import { index as indexAttachments } from "@/services/attachment";
import ParticipantPicker from "@/components/Lead/ParticipantPicker";
import { list as listUnits } from "@/services/units";
import budgetTool from "@/tools/products/budgets/budget";

export default {
  components: {
    CompensationBudgetItem,
    ReparationBudgetItem,
    AdditionalValueItem,
    ContactInput,
    AttachmentInput,
    ParticipantPicker,
    SubstituitionBudgetItem,
    IvaValueItem,
  },

  props: {
    title: String,
    showDetails: {
      type: Boolean,
      default: false,
    },
    objective: String,
    loading: Boolean,
  },

  data() {
    return {
      budgetTool,
      product_budget: {},
      euro: Formatter.euro,
      budgetItems: [
        {
          quantity: "1,0",
        },
      ],
      additionalValue: 0,
      vat_percentage: 0,
      depreciation_percentage: 0,
      attachments: [],
      loadingAttachments: false,
      units: [],
    };
  },

  methods: {
    async getUnits() {
      const units = await listUnits();
      this.units = units;
    },
    async handleSubmit() {
      // Valida todos os itens
      const itemIndex = this.budgetItems.map((item, index) => index);
      let valid = true;

      for (let index of itemIndex) {
        const validation = await this.$refs[`budgetItem${index}`][0].validate();
        if (!validation) valid = false;
      }

      if (!valid) {
        this.$store.commit(
          "error",
          this.$capitalize(this.$tc("model.products_one_or_invalid_items"))
        );
        return;
      }

      // Valida os detalhes (quando são necessários)
      const validation = await this.$refs.budgetForm.validate();
      if (!validation) return;

      // Emite submit
      this.$emit("submit", {
        product_budget: this.product_budget,
        budgetItems: this.budgetItems,
        depreciation_percentage: this.depreciation_percentage,
        additionalValue: this.additionalValue,
        vat_percentage: this.vat_percentage,
      });
    },
    fillNewBudget() {
      this.depreciation_percentage = parseFloat(
        this.product.depreciation_percentage
      );
    },
    fillBudget(budget) {
      this.depreciation_percentage = parseFloat(
        this.product.depreciation_percentage
      );
      this.additionalValue = parseFloat(budget.additional_value);
      this.vat_percentage = parseFloat(budget.vat_percentage) || 0;
      this.product_budget = {
        issued_by: budget.issuer,
        sent_by: budget.sender,
        external_document_id: budget.external_document_id,
      };

      this.budgetItems = [];
      for (const budget of budget.external_items) {
        this.budgetItems.push({
          coverage_id: budget.coverage_id,
          name: budget.description,
          quantity: String(budget.quantity).replace(".", ","),
          value: parseFloat(budget.unit_price),
          total: parseFloat(budget.unit_price) * parseFloat(budget.quantity),
        });
      }

      for (const budget of budget.repair_items) {
        this.budgetItems.push({
          ...budget,
          labor_quantity: String(budget.labor_quantity).replace(".", ","),
          unit_price: parseFloat(budget.unit_price),
          total_material_price: parseFloat(budget.total_material_price),
          total:
            parseFloat(budget.unit_price) * parseFloat(budget.labor_quantity) +
            parseFloat(budget.total_material_price),
        });
      }

      for (const budget of budget.replace_items) {
        this.budgetItems.push({
          ...budget,
          quantity: String(budget.quantity).replace(".", ","),
          unit_price: parseFloat(budget.unit_price),
          total: parseFloat(budget.unit_price) * parseFloat(budget.quantity),
        });
      }
    },
    async handleAddNewItem() {
      this.budgetItems.push({
        quantity: "1,0",
      });
    },
    handleDeleteItem(index) {
      if (this.budgetItems.length > 1) {
        this.budgetItems.splice(index, 1);
      } else {
        this.$refs[`budgetItem${index}`][0].clear();
      }
    },
    async getAttachments() {
      this.loadingAttachments = true;
      const attachments = await indexAttachments(
        this.$route.params.lead_number,
        {
          list: true,
          category_id: 2, // Categoria orçamentos externos
        }
      );
      this.attachments = attachments;
      this.loadingAttachments = false;
    },
  },

  computed: {
    lead() {
      return this.$store.getters.getLead;
    },
    product() {
      return this.$store.getters["product/getProduct"];
    },
    total() {
      let sum = this.subtotal;
      sum -= budgetTool.getDepreciation(
        this.depreciation_percentage,
        this.subtotal
      );
      sum += budgetTool.getAdditional(this.additionalValue, sum);
      sum += budgetTool.getVatValue(this.vat_percentage, sum);

      return sum;
    },
    subtotal() {
      let sum = 0;
      for (const budget of this.budgetItems) {
        sum += budget.total || 0;
      }

      return sum;
    },
    itemComponent() {
      if (this.objective == "compensation") {
        return "CompensationBudgetItem";
      }

      if (this.objective == "reparation") {
        return "ReparationBudgetItem";
      }

      if (this.objective == "substituition") {
        return "SubstituitionBudgetItem";
      }

      return "";
    },
  },

  mounted() {
    if (this.showDetails) {
      this.getAttachments();
    }

    this.getUnits();
  },
};
</script>
