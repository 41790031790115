import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";

// Middlewares
import checkScope from "../middlewares/scope";
import checkMaster from "../middlewares/master";

// Pages
import Home from "../views/Home.vue";
import Login from "../views/Login.vue";
import Logout from "../views/Logout.vue";

// Routers
import leadsRouter from "./leads";

Vue.use(VueRouter);

const routes = [
  {
    path: "/unity_video_call",
    name: "unity_video_call",
    component: () => import("../views/UnityVideoCall.vue"),
  },
  {
    path: "/video_call/not_found",
    name: "video_call_not_found",
    component: () => import("../views/videoCall/VideoCallNotFound.vue"),
  },
  {
    path: "/video_call/error",
    name: "video_call_error",
    component: () => import("../views/videoCall/VideoCallError.vue"),
  },
  {
    path: "/video_call/:key",
    name: "video_call_page",
    component: () => import("../views/videoCall/VideoCallPage.vue"),
  },
  {
    path: "/p/video_call/:key",
    name: "video_call_perit_page",
    component: () => import("../views/videoCall/PeritVideoCallPage.vue"),
  },
  {
    path: "/video_call_ended",
    name: "video_call_ended",
    component: () => import("../views/ParticipantEndVideoCall.vue"),
  },
  {
    path: "/visit_report/:id",
    name: "visit_report",
    component: () => import("../views/VisitReportHome.vue"),
  },
  {
    path: "/login",
    name: "login",
    component: Login,
    beforeEnter: async (to, from, next) => {
      if (await store.dispatch("auth/checkToken")) {
        next(from.path);
      } else {
        next();
      }
    },
  },
  {
    path: "/logout",
    name: "logout",
    component: Logout,
  },
  {
    path: "/",
    name: "",
    component: Home,
    children: [
      {
        path: "/",
        name: "home",
        component: () => import("../views/Dashboard.vue"),
      },
      {
        path: "roles",
        name: "roles",
        beforeEnter: checkScope("auth.roles.read"),
        component: () => import("../views/Roles.vue"),
      },
      {
        path: "scopes",
        name: "scopes",
        beforeEnter: checkScope("auth.scopes.read"),
        component: () => import("../views/Scopes.vue"),
      },
      {
        path: "info_requests",
        name: "info_requests",
        beforeEnter: checkScope("info_requests.read"),
        component: () => import("../views/InformationRequest.vue"),
      },
      {
        path: "letters",
        name: "letters",
        beforeEnter: checkScope("letters.read"),
        component: () => import("../views/Letters.vue"),
      },
      {
        path: "teams",
        name: "teams",
        beforeEnter: checkScope("teams.read"),
        component: () => import("../views/Teams.vue"),
      },
      {
        path: "teams/new",
        name: "teams_new",
        beforeEnter: checkScope("teams.add"),
        component: () => import("../views/TeamsForm.vue"),
      },
      {
        path: "teams/:id",
        name: "teams_update",
        beforeEnter: checkScope("teams.edit"),
        component: () => import("../views/TeamsForm.vue"),
      },
      {
        path: "contacts",
        name: "contacts",
        beforeEnter: checkScope("contacts.read"),
        component: () => import("../views/Contacts.vue"),
      },
      {
        path: "unities",
        name: "unities",
        beforeEnter: checkScope("unities.read"),
        component: () => import("../views/Unities.vue"),
      },
      {
        path: "unities/:id",
        name: "unity_details",
        beforeEnter: checkScope("unities.read"),
        component: () => import("../views/UnityDetails.vue"),
      },
      {
        path: "contacts/new",
        name: "contacts-new",
        beforeEnter: checkScope("contacts.add"),
        component: () => import("../views/NewContact.vue"),
      },
      {
        path: "users",
        name: "users",
        beforeEnter: checkScope("auth.users.read"),
        component: () => import("../views/Users.vue"),
      },
      {
        path: "search",
        name: "search",
        component: () => import("../views/Search.vue"),
      },
      {
        path: "perfil",
        name: "perfil",
        component: () => import("../views/Profile.vue"),
      },
      {
        path: "devzone",
        name: "devzone",
        component: () => import("../views/DevZone"),
      },
      {
        path: "my_schedulings",
        name: "my_schedulings",
        beforeEnter: checkScope("leads.my_schedulings.read"),
        component: () => import("../views/MySchedulings.vue"),
      },
      {
        path: "managers",
        name: "managers",
        beforeEnter: checkScope("service_managers.read"),
        component: () => import("../views/Managers.vue"),
      },
      {
        path: "image_predict",
        name: "image_predict",
        component: () => import("../views/ImagePredict.vue"),
      },
      {
        path: "lusitania/robots/jobs",
        name: "lusitania_robots_jogs",
        component: () => import("../views/Lusitania/Robots/Jobs.vue"),
      },
      {
        path: "automations/eps",
        name: "automations_eps",
        beforeEnter: checkScope("massive_actions.eps.read"),
        component: () =>
          import("../views/automations/eps/EpsAutomationsPage.vue"),
      },
      {
        path: "i18n",
        name: "i18n",
        beforeEnter: checkScope("internationalization.read"),
        component: () => import("../views/i18n.vue"),
      },
      {
        path: "eps",
        name: "eps",
        beforeEnter: checkScope("leads.integration.read"),
        component: () => import("../views/Eps.vue"),
      },
      {
        path: "reminders",
        name: "reminders",
        component: () => import("../views/RemindersPage.vue"),
      },
      {
        path: "supervision",
        name: "supervision",
        component: () => import("../views/Supervision.vue"),
      },
      {
        path: "supervision/unity-distribution",
        name: "supervision-unity-distribution",
        component: () =>
          import("../views/supervision/LeadDistributionAnalytics.vue"),
      },
      {
        path: "eps/notifications/:id",
        name: "eps",
        beforeEnter: checkScope("leads.integration.read"),
        component: () => import("../views/EpsNotificationPage.vue"),
      },
      {
        path: "eps/notification/new",
        name: "new_notification",
        beforeEnter: checkMaster,
        component: () => import("../views/EpsNewNotification.vue"),
      },
      {
        path: "hcm_universe/portal_companhias",
        name: "hcm_universe.portal_companhias",
        beforeEnter: checkScope("portal_companhias.users.read"),
        component: () => import("@/views/PortalCompanhias/Insurers.vue"),
      },
      {
        path: "hcm_universe/portal_companhias/:id/users",
        name: "hcm_universe.portal_companhias.users",
        beforeEnter: checkScope("portal_companhias.users.read"),
        component: () => import("@/views/PortalCompanhias/InsurerUsers.vue"),
        props: true,
      },
      {
        path: "configs",
        beforeEnter: checkScope("configs.read"),
        component: () => import("../views/Configs"),
        children: [
          {
            path: "",
            name: "configs",
            redirect: {
              name: "attachment_categories_configs",
            },
          },
          {
            path: "emails/unities",
            name: "emails_unities",
            beforeEnter: checkScope("configs.read"),
            component: () =>
              import("../views/configs/emails/EmailsUnitiesConfigs"),
          },
          {
            path: "attachments/insurer/restrictions",
            name: "attachment_insurer_restrictions_configs",
            beforeEnter: checkScope("configs.read"),
            component: () =>
              import("../views/configs/AttachmentRestrictionsConfigs"),
          },
          {
            path: "attachments/insurer/available_categories",
            name: "attachment_insurer_available_categories_configs",
            beforeEnter: checkScope("configs.read"),
            component: () =>
              import(
                "../views/configs/AttachmentInsurerAvailableCategoriesConfigs"
              ),
          },
          {
            path: "attachments/unity/available_categories",
            name: "attachment_unity_available_categories_configs",
            beforeEnter: checkScope("configs.read"),
            component: () =>
              import(
                "../views/configs/AttachmentUnityAvailableCategoriesConfigs"
              ),
          },
          {
            path: "attachments/upload",
            name: "attachment_upload_configs",
            beforeEnter: checkScope("configs.read"),
            component: () => import("../views/configs/AttachmentUploadConfigs"),
          },
          {
            path: "eps/attachments",
            name: "eps_attachments_configs",
            beforeEnter: checkScope("configs.eps.read"),
            component: () =>
              import("../views/configs/eps/EpsAttachmentsConfig"),
          },
          {
            path: "eps/attachment_categories",
            name: "eps_attachment_categories",
            beforeEnter: checkScope("configs.eps.read"),
            component: () =>
              import("../views/configs/eps/EpsAttachmentCategories"),
          },
          {
            path: "eps/blocked_attachment_types",
            name: "eps_blocked_attachment_types",
            beforeEnter: checkScope("configs.eps.read"),
            component: () =>
              import("../views/configs/eps/EpsBlockedAttachmentTypes"),
          },
          {
            path: "attachments/categories",
            name: "attachment_categories_configs",
            beforeEnter: checkScope("configs.read"),
            component: () =>
              import("../views/configs/AttachmentCategoriesConfigs"),
          },
          {
            path: "attachments/default_categories",
            name: "attachment_default_categories_configs",
            beforeEnter: checkScope("configs.read"),
            component: () =>
              import("../views/configs/AttachmentDefaultCategoriesConfigs"),
          },
          {
            path: "leads_distribution/availability_score",
            name: "unities_availability_score",
            beforeEnter: checkScope("configs.read"),
            component: () =>
              import("../views/configs/unities/UnityScoresConfigs"),
          },
          {
            path: "leads_distribution/activate_deactivate",
            name: "leads_distribution_activate_deactivate",
            beforeEnter: checkScope("configs.read"),
            component: () =>
              import(
                "../views/configs/leadsDistribution/ActivateAndDeactivateConfigs"
              ),
          },
          {
            path: "leads/statuses",
            name: "configs.leads.statuses",
            beforeEnter: checkScope("configs.leads.statuses.edit"),
            component: () =>
              import("@/views/configs/leads/statuses/LeadStatusesConfig.vue"),
          },
          {
            path: "leads/statuses/insurers",
            name: "configs.leads.statuses.insurers",
            beforeEnter: checkScope("configs.leads.statuses.edit"),
            component: () =>
              import(
                "@/views/configs/leads/statuses/ExternalStatusesConfig.vue"
              ),
            props: {
              entity: "insurers",
              title: "Portal das Companhias",
            },
          },
          {
            path: "leads/statuses/unities",
            name: "configs.leads.statuses.unities",
            beforeEnter: checkScope("configs.leads.statuses.edit"),
            component: () =>
              import(
                "@/views/configs/leads/statuses/ExternalStatusesConfig.vue"
              ),
            props: {
              entity: "unities",
              title: "Portal das Unidades",
            },
          },
          {
            path: "leads/active_statuses_unities",
            name: "leads_active_statuses_unities",
            beforeEnter: checkScope("configs.read"),
            component: () =>
              import(
                "../views/configs/leads/statuses/ActiveStatusesForUnities"
              ),
          },
          {
            path: "leads/completed_statuses",
            name: "leads_completed_statuses",
            beforeEnter: checkScope("configs.read"),
            component: () =>
              import("../views/configs/leads/statuses/CompletedStatuses"),
          },
          {
            path: "equipaments/types",
            name: "config_equipament_types",
            beforeEnter: checkScope("configs.read"),
            component: () =>
              import("../views/configs/equipaments/EquipamentTypesConfigs.vue"),
          },
          {
            path: "leads/statuses_automation",
            name: "leads_statuses_automation",
            beforeEnter: checkScope("configs.read"),
            component: () =>
              import("../views/configs/leads/statuses/StatusAutomation"),
          },
          {
            path: "leads/block_statuses_automation",
            name: "leads_block_statuses_automation",
            beforeEnter: checkScope("configs.read"),
            component: () =>
              import("../views/configs/leads/statuses/BlockStatusAutomation"),
          },
          {
            path: "insurers_portal",
            name: "configs.insurers_portal",
            beforeEnter: checkScope("configs.insurers_portal.theme.edit"),
            component: () =>
              import("@/views/configs/insurers_portal/InsurersPage.vue"),
          },
          {
            path: "insurers_portal/:id",
            beforeEnter: checkScope("configs.insurers_portal.theme.edit"),
            props: true,
            component: () =>
              import("@/views/configs/insurers_portal/InsurerPage.vue"),
            children: [
              {
                path: "",
                name: "configs.insurers_portal.show",
                redirect: {
                  name: "configs.insurers_portal.theme",
                },
              },
              {
                path: "theme",
                name: "configs.insurers_portal.theme",
                props: true,
                component: () =>
                  import(
                    "@/views/configs/insurers_portal/InsurerThemePage.vue"
                  ),
              },
              {
                path: "users",
                name: "configs.insurers_portal.users",
                props: true,
                component: () =>
                  import(
                    "@/views/configs/insurers_portal/InsurerUsersPage.vue"
                  ),
              },
              {
                path: "users/:user_id",
                name: "configs.insurers_portal.users.show",
                props: true,
                component: () =>
                  import(
                    "@/views/configs/insurers_portal/InsurerUsersShowPage.vue"
                  ),
              },
            ],
          },
          {
            path: "form_options/categories",
            component: () =>
              import(
                "@/views/configs/form/options/FormOptionCategoriesPage.vue"
              ),
            children: [
              {
                path: "",
                name: "form-options-categories",
                component: () =>
                  import(
                    "@/views/configs/form/options/FormOptionEmptyPage.vue"
                  ),
              },
              {
                path: ":category_id",
                name: "form-options-category",
                props: true,
                component: () =>
                  import("@/views/configs/form/options/FormOptionPage.vue"),
              },
            ],
          },
        ],
      },
      ...leadsRouter,
    ],
  },
  {
    path: "*",
    component: Home,
    beforeEnter: (to, from, next) => {
      next("/");
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
